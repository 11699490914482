<template>
  <div>
    <div style="display: flex; justify-content: space-between; height: 40px">
      <el-form ref="form" :model="queryParams" :inline="true">
        <el-form-item label="公司名称">
          <el-input
            placeholder="公司名称"
            v-model="queryParams.deptName"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="queryParams.status">
            <el-option label="正常" value="0"></el-option>
            <el-option label="停用" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search " @click="getList"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button type="success" icon="el-icon-plus" @click="handleAdd"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-card style="margin-top: 40px">
      <el-table
        :data="deptList"
        style="width: 100%; margin-bottom: 20px"
        row-key="deptId"
        border:default-expand-all="isExpandAll"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="deptName" label="公司名称" fixed="left">
        </el-table-column>
        <el-table-column prop="orderNum" label="排序"> </el-table-column>
        <el-table-column prop="status" label="状态">
          <template scope="scope">
            <span>{{ scope.row.status == '0' ? '正常' : '停用' }}</span>
          </template>
           </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
           </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed="right"
          >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-plus"
              @click="handleAdd(scope.row)"
              >新增</el-button
            >
            <el-button
              v-if="scope.row.parentId != 0"
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加或修改部门对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row>
          <el-col :span="24" v-if="form.parentId !== 0">
            <el-form-item label="上级部门" prop="parentId">
              <treeselect
                v-model="form.parentId"
                :options="deptOptions"
                :normalizer="normalizer"
                placeholder="选择上级部门"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="部门名称" prop="deptName">
              <el-input v-model="form.deptName" placeholder="请输入部门名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="显示排序" prop="orderNum">
              <el-input-number
                v-model="form.orderNum"
                controls-position="right"
                :min="0"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="负责人" prop="leader">
              <el-input
                v-model="form.leader"
                placeholder="请输入负责人"
                maxlength="20"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入联系电话"
                maxlength="11"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input
                v-model="form.email"
                placeholder="请输入邮箱"
                maxlength="50"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部门状态">
              <el-radio-group v-model="form.status">
                <el-radio label="0">正常</el-radio>
                <el-radio label="1">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row   v-if="upImage== 0" >
          <el-col >
            <el-form-item label="电子合同印章">
            <el-upload
              class="upload-demo"
              action="https://uploadfile.hellouxing.com/uploadfile/upload"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :on-success="handleAvatarSuccess"
              multiple
              :limit="1"
              :on-exceed="handleExceed"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过500kb
              </div>
            </el-upload>
          </el-form-item>
          </el-col>
        </el-row>
        <el-row  v-else >
          <div style="position: relative;">
            <i class="el-icon-close" style="position:absolute" @click="upImage=0"></i>
            <img :src="form.sealImg"  style="width: 80px;height: 80px; margin-left: 20px; ">
          </div>

      </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listDept,
  systemDept,
  listDeptExcludeChild,
  getDept,
  updateDept,
  delDept,
} from "../api";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect },
  data() {
    return {
      upImage:0,
      fileList: [],
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 表格树数据
      deptList: [],
      // 部门树选项
      deptOptions: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 是否展开，默认全部展开
      isExpandAll: false,
      // 重新渲染表格状态
      refreshTable: true,
      //电子合同印章
      sealImg:'',
      // 查询参数
      queryParams: {
        deptName: undefined,
        status: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        parentId: [
          { required: true, message: "上级部门不能为空", trigger: "blur" },
        ],
        deptName: [
          { required: true, message: "部门名称不能为空", trigger: "blur" },
        ],
        orderNum: [
          { required: true, message: "显示排序不能为空", trigger: "blur" },
        ],
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleAvatarSuccess(res) {
      this.sealImg = res.data
      },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    /** 查询部门列表 */
    getList() {
      listDept(this.queryParams).then((data) => {
        const dict = data.data;
        console.log(dict);
        if (dict.code === 200) {
          this.deptList = this.handleTree(dict.data, "deptId");
          console.log(this.deptList);
        } else {
          this.$message.error(dict.msg);
        }
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.deptId != undefined) {
            console.log(this.form);
            this.form.sealImg =!this.sealImg?this.form.sealImg:this.sealImg;
            updateDept(this.form).then(() => {
              this.$message.success("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            if(!this.sealImg){
              this.$message.error("请上传合同印章");
              return;
            }
            this.form.sealImg = this.sealImg
            systemDept(this.form).then(() => {
              this.$message.success("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 转换部门数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.deptId,
        label: node.deptName,
        children: node.children,
      };
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.upImage = 0;
      this.reset();
      if (row != undefined) {
        this.form.parentId = row.deptId;
      }
      this.open = true;
      this.title = "添加部门";
      listDept().then((data) => {
        const dict = data.data;
        console.log(dict);
        if (dict.code === 200) {
          this.deptOptions = this.handleTree(dict.data, "deptId");
          console.log(this.deptList);
        } else {
          this.$message.error(dict.msg);
        }
      });
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.upImage = 1;
      this.reset();
      getDept(row.deptId).then((response) => {
        this.form = response.data.data;
        this.open = true;
        this.title = "修改部门";
        listDeptExcludeChild(row.deptId).then((response) => {
          this.deptOptions = this.handleTree(response.data.data, "deptId");
          if (this.deptOptions.length == 0) {
            const noResultsOptions = {
              deptId: this.form.parentId,
              deptName: this.form.parentName,
              children: [],
            };
            this.deptOptions.push(noResultsOptions);
          }
        });
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        "是否确认删除名称为" + row.deptName + '"的数据项？',
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          delDept(row.deptId).then((data) => {
            if (data.data.code === 200) {
              this.getList();
              this.$message.success("删除成功");
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch(() => {});
    },
    // 表单重置
    reset() {
      this.form = {
        jobId: undefined,
        jobName: undefined,
        jobGroup: undefined,
        invokeTarget: undefined,
        cronExpression: undefined,
        misfirePolicy: 1,
        concurrent: 1,
        status: "0",
      };
      this.resetForm("form");
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
  },
};
</script>